import React, { useEffect, useState } from "react";
import {
    Button,
    ColumnLayout,
    ExpandableSection,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Textarea,
    Modal,
    Box,
    Select,
    Grid, FlashbarProps, Link, Flashbar
} from "@amzn/awsui-components-react";
import { RegistrationRecord } from "src/models/data-registration/RegistrationRecord";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalAppContext } from "src/components/App";
import { formatDate } from "src/utils/DateUtils";
import { customHelpPanel } from "src/components/CustomComponents";
import CONSTANTS from "src/utils/constants";
import './styles/ShowRecord.scss';
import ApprovalWorkflow from 'src/components/ApprovalWorkflow';
import ShowApprovalWorkflowActions from "src/components/ShowApprovalWorkflowActions";
import ServiceCollection from "src/services/ServiceCollection";

interface ShowRecordProps {
    services: ServiceCollection;
    userAlias: string;
    isAccountingUser: boolean;
    isTaxUser: boolean;
    isSuperUser: boolean;
}

export default function ShowRecord(props : ShowRecordProps) {
    const { services, userAlias, isAccountingUser, isTaxUser, isSuperUser } = props;
    const history = useHistory();
    const location = useLocation<any>();
    const registrationRecord = location.state as RegistrationRecord;
    const { isViewer } = React.useContext(GlobalAppContext);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [activateModalVisible, setActivateModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [discardModalVisible, setDiscardModalVisible] = useState(false);

    const IN_ACCOUNTING_APPROVAL = CONSTANTS.APPROVAL_WORKFLOW_STAGES.IN_ACCOUNTING_APPROVAL;
    const IN_TAX_APPROVAL = CONSTANTS.APPROVAL_WORKFLOW_STAGES.IN_TAX_APPROVAL;
    const ACTIVE = CONSTANTS.APPROVAL_WORKFLOW_STAGES.ACTIVE;
    const INACTIVE = CONSTANTS.APPROVAL_WORKFLOW_STAGES.INACTIVE;
    const CANCELLED = CONSTANTS.APPROVAL_WORKFLOW_STAGES.CANCELLED;

    const ACCOUNTING_APPROVER_UNDEFINED = undefined;
    const TAX_APPROVER_UNDEFINED = undefined;
    const CANCELLED_BY_UNDEFINED = undefined;
    const DEACTIVATED_BY_UNDEFINED = undefined;
    const REACTIVATED_BY_UNDEFINED = undefined;

    interface SelectedOption {
        label: string,
        value: string
    }

    function goToEditPage() {
        history.push({
            pathname: '/edit',
            state: registrationRecord
        });
    }

    function submitForApproval() {
        const registrationRecordToUpdate = buildRegistrationRecordToUpdate(IN_ACCOUNTING_APPROVAL,
          ACCOUNTING_APPROVER_UNDEFINED,
          TAX_APPROVER_UNDEFINED,
          CANCELLED_BY_UNDEFINED,
          DEACTIVATED_BY_UNDEFINED,
          REACTIVATED_BY_UNDEFINED);
        updateRegistrationRecord(registrationRecordToUpdate);
    }

    function accountingApprove() {
         const registrationRecordToUpdate = buildRegistrationRecordToUpdate(registrationRecord.taxApprover ? ACTIVE : IN_TAX_APPROVAL,
           userAlias,
           TAX_APPROVER_UNDEFINED,
           CANCELLED_BY_UNDEFINED,
           DEACTIVATED_BY_UNDEFINED,
           REACTIVATED_BY_UNDEFINED);
        updateRegistrationRecord(registrationRecordToUpdate);
    }

    function taxApprove() {
        const registrationRecordToUpdate = buildRegistrationRecordToUpdate(registrationRecord.accountingApprover ? ACTIVE : IN_ACCOUNTING_APPROVAL,
          ACCOUNTING_APPROVER_UNDEFINED,
          userAlias,
          CANCELLED_BY_UNDEFINED,
          DEACTIVATED_BY_UNDEFINED,
          REACTIVATED_BY_UNDEFINED);
        updateRegistrationRecord(registrationRecordToUpdate);
    }

    function deactivate() {
        const registrationRecordToUpdate = buildRegistrationRecordToUpdate(INACTIVE,
          ACCOUNTING_APPROVER_UNDEFINED,
          TAX_APPROVER_UNDEFINED,
          CANCELLED_BY_UNDEFINED,
          userAlias,
          REACTIVATED_BY_UNDEFINED);
        updateRegistrationRecord(registrationRecordToUpdate);
   }

    function activate() {
        setActivateModalVisible(false);
        const registrationRecordToUpdate = buildRegistrationRecordToUpdate(ACTIVE,
          ACCOUNTING_APPROVER_UNDEFINED,
          TAX_APPROVER_UNDEFINED,
          CANCELLED_BY_UNDEFINED,
          DEACTIVATED_BY_UNDEFINED,
          userAlias);
        updateRegistrationRecord(registrationRecordToUpdate);
    }

    function cancel() {
        setCancelModalVisible(false);
        const registrationRecordToUpdate = buildRegistrationRecordToUpdate(CANCELLED,
          ACCOUNTING_APPROVER_UNDEFINED,
          TAX_APPROVER_UNDEFINED,
          userAlias,
          DEACTIVATED_BY_UNDEFINED,
          REACTIVATED_BY_UNDEFINED);
        updateRegistrationRecord(registrationRecordToUpdate);
    }

    function buildRegistrationRecordToUpdate(approvalWorkflowStage: string,
                                             accountingApprover: string | undefined,
                                             taxApprover: string | undefined,
                                             cancelledBy: string | undefined,
                                             deactivatedBy: string | undefined,
                                             reactivatedBy: string | undefined) {
        const registrationRecordToUpdate: RegistrationRecord = {
            baseRegistrationNumber: registrationRecord.baseRegistrationNumber,
            approvalWorkflowStage: approvalWorkflowStage,
            atpCalculationFlag: registrationRecord.atpCalculationFlag,
            currency: registrationRecord.currency,
            effectiveFromDate: registrationRecord.effectiveFromDate,
            effectiveToDate: registrationRecord.effectiveToDate,
            workbookName: registrationRecord.workbookName,
            paymentTerms: registrationRecord.paymentTerms,
            beat: registrationRecord.beat,
            description: registrationRecord.description,
            comments: registrationRecord.comments,
            providerCompanyCode: registrationRecord.providerCompanyCode,
            providerEntityName: registrationRecord.providerEntityName,
            providerJurisdiction: registrationRecord.providerJurisdiction,
            recipientCompanyCode: registrationRecord.recipientCompanyCode,
            recipientEntityName: registrationRecord.recipientEntityName,
            recipientJurisdiction: registrationRecord.recipientJurisdiction,
            providerTaxRegConsideration: registrationRecord.providerTaxRegConsideration,
            recipientTaxRegConsideration: registrationRecord.recipientTaxRegConsideration,
            accountingOwner: registrationRecord.accountingOwner,
            taxOwner: registrationRecord.taxOwner,
            taxProductId: registrationRecord.taxProductId,
            taxProductName: registrationRecord.taxProductName,
            taxProductCategoryId: registrationRecord.taxProductCategoryId,
            taxProductCategoryName: registrationRecord.taxProductCategoryName,
            exportOfServices: registrationRecord.exportOfServices,
            accountingApprover: accountingApprover ?? registrationRecord.accountingApprover,
            taxApprover: taxApprover ?? registrationRecord.taxApprover,
            cancelledBy: cancelledBy ?? registrationRecord.cancelledBy,
            deactivatedBy: deactivatedBy ?? registrationRecord.deactivatedBy,
            reactivatedBy: reactivatedBy ?? registrationRecord.reactivatedBy,
            createdBy: registrationRecord.createdBy,
            lastUpdatedByAccountingUser: registrationRecord.lastUpdatedByAccountingUser,
            lastUpdatedByTaxUser: registrationRecord.lastUpdatedByTaxUser
        }

        return registrationRecordToUpdate;
    }

    async function updateRegistrationRecord(registrationRecordToUpdate: RegistrationRecord) {
        const {updatedRegistrationRecord, error} =
          await services.registrationDataService.updateRegistrationRecord(registrationRecordToUpdate);

        if (error) {
            services.messageService.showErrorAutoDismissBanner(error);
            return;
        }

        history.push({
            pathname: '/show',
            state: updatedRegistrationRecord
        });
    }

    async function getPastRevisionData(selectedOption: SelectedOption) {
        const { revisionRecord, error } =
          await services.registrationDataService.getRevisionRecord(registrationRecord.baseRegistrationNumber!, Number(selectedOption.value));

        if (error) {
            services.messageService.showErrorAutoDismissBanner(error);
            return;
        }

        setDiscardModalVisible(false);

        history.push({
            pathname: '/show',
            state: revisionRecord
        });
    }

    async function getLatestRevisionRecord() {
        const isLatestRev = true;
        const { revisionRecord, error } =
            await services.registrationDataService.getRevisionRecord(registrationRecord.baseRegistrationNumber!, undefined, isLatestRev);

        if (error) {
            services.messageService.showErrorAutoDismissBanner(error);
            return;
        }

        history.push({
            pathname: '/show',
            state: revisionRecord
        });
    }

    async function discardWorkflowRevision() {
        const { lastApprovedRecord, error } = await services.registrationDataService.discardWorkflowRevision(
          registrationRecord.baseRegistrationNumber!, registrationRecord.approvalWorkflowRev!, userAlias);

        if (error) {
            services.messageService.showErrorAutoDismissBanner(error);
            return;
        }

        // If the last approved record exists, display it.
        if (lastApprovedRecord && lastApprovedRecord.registrationNumber) {
            setSelectedOption({label: 'Revision ' + lastApprovedRecord!.approvalWorkflowRev! + ' (Current)',
                value: lastApprovedRecord!.approvalWorkflowRev!.toString()});
            setDiscardModalVisible(false);

            history.push({
                pathname: '/show',
                state: lastApprovedRecord
            });
            return;
        }

        history.push({
            pathname: '/'
        });
    }

    // When show page URL is directly posted in browser, registration record will be undefined.
    if (registrationRecord === undefined) {
        history.push({
            pathname: '/'
        });
        return <></>;
    }

    const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([]);
    const [selectedOption, setSelectedOption] = useState<{ label: string, value: string } | null>(null);
    const [allRevisions, setAllRevisions] = useState<SelectedOption[]>([]);

    useEffect(() => {
        if (registrationRecord.latestRev) {
            const workflowRevCount = registrationRecord.approvalWorkflowRev!;
            const revisions = [];

            for (let workflowRev = workflowRevCount; workflowRev >= 0; workflowRev--) {
                const isCurrentRev = workflowRev === workflowRevCount;
                const label = `Revision ${workflowRev}${isCurrentRev ? ' (Current)' : ''}`;

                revisions.push({ label, value: workflowRev.toString() });

                if (isCurrentRev) {
                    setSelectedOption({ label, value: workflowRev.toString() });
                }
            }
            setAllRevisions(revisions);
        } else {
            setItems([{
                type: 'info',
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setItems([]),
                content: (
                    <>
                        You are not viewing the latest revision.{" "}
                        <Link data-testid="go-to-latest-rev-link" color="inverted" onFollow={() => getLatestRevisionRecord()}>Go to the latest revision</Link>.
                    </>
                ),
                id: 'message_1',
            }]);
        }
    }, [registrationRecord]);

    let stage = registrationRecord.approvalWorkflowStage;

    return (
        <div>
            {registrationRecord !== undefined ?
              <div>
                <Modal onDismiss={() => setDiscardModalVisible(false)} visible={discardModalVisible}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction ="horizontal" size="xs">
                                <Button variant="primary" onClick={discardWorkflowRevision} data-testid="discard-modal-yes-button">Yes</Button>
                                <Button variant="link" onClick={() => setDiscardModalVisible(false)}>No</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="Discard Current Revision" data-testid="discard-modal"
                >
                    Discarding this current revision will result in revert back to last approved revision if present.
                    This action cannot be undone. Are you sure you want to proceed?
                </Modal>
                <Modal onDismiss={() => setCancelModalVisible(false)} visible={cancelModalVisible}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary" onClick={cancel} data-testid="cancel-modal-yes-button">Yes</Button>
                                <Button variant="link" onClick={() => setCancelModalVisible(false)}>No</Button>
                            </SpaceBetween>
                        </Box>
                            }
                    header="Cancel Registration Record" data-testid="cancel-modal"
                >
                    Cancelling this record cannot be undone. Are you sure you want to proceed?
                </Modal>
                <Modal onDismiss={() => setActivateModalVisible(false)} visible={activateModalVisible}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary" onClick={activate} data-testid="activate-modal-yes-button">Yes</Button>
                                <Button variant="link" onClick={() => setActivateModalVisible(false)}>No</Button>
                            </SpaceBetween>
                        </Box>
                            }
                    header="Activate Registration Record" data-testid="activate-modal"
                >
                    Activating this record will move it back into the Active stage. Are you sure you want to proceed?
                </Modal>
                <Modal onDismiss={() => setEditModalVisible(false)} visible={editModalVisible}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary" onClick={() => {setEditModalVisible(false);goToEditPage()}} data-testid="edit-modal-yes-button">Yes</Button>
                                <Button variant="link" onClick={() => setEditModalVisible(false)}>No</Button>
                            </SpaceBetween>
                        </Box>
                            }
                    header="Edit Registration Record" data-testid="edit-modal"
                    >
                    Editing this record will move it back to draft. Are you sure you want to do proceed?
                </Modal>
                <SpaceBetween size="xl">
                    {registrationRecord.latestRev! ?
                        <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
                            <div></div>
                            <FormField label="Select Past Revision">
                                <Select data-testid="select-past-rev-data" selectedOption={selectedOption!}
                                        options={allRevisions}
                                        onChange={({ detail: { selectedOption } }) => {
                                            if (Number(selectedOption.value) !== registrationRecord.approvalWorkflowRev) {
                                                getPastRevisionData(selectedOption as SelectedOption);
                                            }
                                        }}
                                />
                          </FormField>
                        </Grid> :
                        <Flashbar data-testid="go-to-latest-rev" items={items}/>
                    }
                    <ApprovalWorkflow currentUser={userAlias} registrationRecord={registrationRecord} />
                    <Form header={
                        <Grid gridDefinition={[{colspan:4}, {colspan: 8}]}>
                            <Header variant="h1">Registration Record</Header>
                            <div data-class="actions">
                                <ShowApprovalWorkflowActions
                                    registrationRecord={registrationRecord}
                                    stage={stage}
                                    isViewer={isViewer}
                                    isAccountingUser={isAccountingUser}
                                    isTaxUser={isTaxUser}
                                    isSuperUser={isSuperUser}
                                    userAlias={userAlias}
                                    setEditModalVisible={setEditModalVisible}
                                    goToEditPage={goToEditPage}
                                    setDiscardModalVisible={setDiscardModalVisible}
                                    submitForApproval={submitForApproval}
                                    accountingApprove={accountingApprove}
                                    taxApprove={taxApprove}
                                    deactivate={deactivate}
                                    setActivateModalVisible={setActivateModalVisible}
                                    setCancelModalVisible={setCancelModalVisible}
                                />
                            </div>
                        </Grid>
                    }>
                        <div className="display-registration-record" style={{ backgroundColor: 'whitesmoke' }}>
                            <ExpandableSection headerText="Registration Details" variant="container" defaultExpanded>
                                <ColumnLayout columns={2}>
                                    <FormField label="Registration Number">
                                        <Input value={registrationRecord.registrationNumber!} disabled />
                                    </FormField>
                                    <FormField />
                                    <FormField label="ATP Calculation">
                                        <Input value={String(registrationRecord.atpCalculationFlag)} disabled />
                                    </FormField>
                                    <FormField label="Currency">
                                        <Input value={registrationRecord.currency ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Effective From Date">
                                        <Input value={registrationRecord.effectiveFromDate ?
                                            formatDate(new Date(registrationRecord.effectiveFromDate)) : ''} disabled />
                                    </FormField>
                                    <FormField label="Effective To Date">
                                        <Input value={registrationRecord.effectiveToDate ?
                                            formatDate(new Date(registrationRecord.effectiveToDate)) : ''} disabled />
                                    </FormField>
                                    <FormField label="Workbook Name" info={customHelpPanel(CONSTANTS.FIELDS_INFO.WORKBOOK_NAME)}>
                                        <Input value={registrationRecord.workbookName ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Payment Terms" info={customHelpPanel(CONSTANTS.FIELDS_INFO.PAYMENT_TERMS)}>
                                        <Input value={registrationRecord.paymentTerms ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Beat" info={customHelpPanel(CONSTANTS.FIELDS_INFO.BEAT)}>
                                        <Input value={registrationRecord.beat ?? ''} disabled />
                                    </FormField>
                                    <FormField />
                                    <FormField label="Description">
                                        <Textarea value={registrationRecord.description ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Comments" info={customHelpPanel(CONSTANTS.FIELDS_INFO.COMMENTS)}>
                                        <Textarea value={registrationRecord.comments ?? ''} disabled />
                                    </FormField>
                                </ColumnLayout>
                            </ExpandableSection>
                            <ExpandableSection headerText="Party Information" variant="container" defaultExpanded>
                                <ColumnLayout columns={2}>
                                    <FormField label="Provider Company Code" info={customHelpPanel(CONSTANTS.FIELDS_INFO.PROVIDER_COMPANY_CODE)}>
                                        <Input value={registrationRecord.providerCompanyCode} disabled />
                                    </FormField>
                                    <FormField label="Recipient Company Code" info={customHelpPanel(CONSTANTS.FIELDS_INFO.RECIPIENT_COMPANY_CODE)}>
                                        <Input value={registrationRecord.recipientCompanyCode} disabled />
                                    </FormField>
                                    <FormField label="Provider Entity Name">
                                        <Input value={registrationRecord.providerEntityName ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Recipient Entity Name">
                                        <Input value={registrationRecord.recipientEntityName ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Provider Jurisdiction">
                                        <Input value={registrationRecord.providerJurisdiction ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Recipient Jurisdiction">
                                        <Input value={registrationRecord.recipientJurisdiction ?? ''} disabled />
                                    </FormField>
                                </ColumnLayout>
                            </ExpandableSection>
                            <ExpandableSection headerText="Registration Record Contributors" variant="container" defaultExpanded>
                                <ColumnLayout columns={2}>
                                    <FormField label="Accounting Owner">
                                        <Input value={registrationRecord.accountingOwner ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Tax Owner">
                                        <Input value={registrationRecord.taxOwner ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Created By">
                                        <Input value={registrationRecord.createdBy} disabled />
                                    </FormField>
                                    <FormField />
                                    <FormField label="Last Updated By Accounting User">
                                        <Input value={registrationRecord.lastUpdatedByAccountingUser ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Last Updated By Tax User">
                                        <Input value={registrationRecord.lastUpdatedByTaxUser ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Created Date Time">
                                        <Input value={formatDate(new Date(registrationRecord.createDateTime!))} disabled />
                                    </FormField>
                                    <FormField label="Last Updated Date Time">
                                        <Input value={formatDate(new Date(registrationRecord.lastUpdatedDateTime!))} disabled />
                                    </FormField>
                                </ColumnLayout>
                            </ExpandableSection>
                            <ExpandableSection headerText="Tax Product Details" variant="container" defaultExpanded>
                                <ColumnLayout columns={2}>
                                    <FormField label="Provider Tax Reg Consideration">
                                        <Input value={String(registrationRecord.providerTaxRegConsideration)} disabled />
                                    </FormField>
                                    <FormField label="Recipient Tax Reg Consideration">
                                        <Input value={String(registrationRecord.recipientTaxRegConsideration)} disabled />
                                    </FormField>
                                    <FormField label="Tax Product Category Name">
                                        <Input value={registrationRecord.taxProductCategoryName ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Tax Product Category Id">
                                        <Input value={registrationRecord.taxProductCategoryId ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Tax Product Name">
                                        <Input value={registrationRecord.taxProductName ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Tax Product Id">
                                        <Input value={registrationRecord.taxProductId ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Export of Services" info={customHelpPanel(CONSTANTS.FIELDS_INFO.EXPORT_OF_SERVICES)}>
                                        <Input value={String(registrationRecord.exportOfServices)} disabled />
                                    </FormField>
                                </ColumnLayout>
                            </ExpandableSection>
                        </div>
                    </Form> :<></>
                </SpaceBetween>
              </div> :<></>
            }
        </div>
    );
}