import './styles/SearchRecords.scss';
import React, { useContext } from "react";
import {
    Button,
    Link,
    ColumnLayout,
    Form,
    FormField,
    Header,
    Input,
    StatusIndicator,
    Autosuggest,
} from "@amzn/awsui-components-react";
import { RegistrationRecord } from "src/models/data-registration/RegistrationRecord";
import { useHistory } from "react-router-dom";
import ServiceCollection from "src/services/ServiceCollection";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import Select from "@amzn/awsui-components-react/polaris/select";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Constants from "src/utils/constants";
import { formatDate } from "src/utils/DateUtils";
import { getCompanyCodesFromALE } from "src/utils/ALEUtils";
import { GlobalAppContext } from "src/components/App";
import { createLabelValueList } from "src/utils/commonComponentUtils";

interface SelectedOption {
    label: string,
    value: string
}

export default function SearchRecords(props: { services: ServiceCollection }) {
    const {services} = props;
    const [registrationNumber, setRegistrationNumber] = React.useState('');
    const [providerCompanyCode, setProviderCompanyCode] = React.useState('');
    const [recipientCompanyCode, setRecipientCompanyCode] = React.useState('');
    const [approvalWorkflowStage, setApprovalWorkflowStage] = React.useState({label: 'None', value: 'None'});
    const [currentPageIndex, setCurrentPageIndex] = React.useState(0);
    const [getRegistrationRecordsMap] = React.useState(new Map<number, RegistrationRecord[]>());
    const [nextToken, setNextToken] = React.useState('');
    const [loadingRegistrationRecords, setLoadingRegistrationRecords] = React.useState(false);
    const [searchDone, setSearchDone] = React.useState(false);
    const [loadingCompanyCodes, setLoadingCompanyCodes] = React.useState(false);
    const [fetchCompanyCodesErrorMessage, setFetchCompanyCodesErrorMessage] = React.useState('');
    const history = useHistory();

    const emptyNextToken = '';

    const { companyCodes, setCompanyCodes } = useContext(GlobalAppContext);

    const stages = Constants.APPROVAL_WORKFLOW_STAGES;

    const { items, collectionProps } = useCollection(
        getRegistrationRecordsMap.get(currentPageIndex) ? getRegistrationRecordsMap.get(currentPageIndex)! : [],
      {
          sorting: {}
      }
    );

    const paginationLinks = [];
    for (let pageNumber of getRegistrationRecordsMap.keys()) {
        paginationLinks.push(
          <Link data-id='pagination-link' key={pageNumber} onFollow={() => setCurrentPageIndex(pageNumber)}>
              {pageNumber}
          </Link>
        );
    }

    function getCompanyCodes() {
        // If company codes are already loaded from React context, just return.
        if (companyCodes.length != 0) {
            return;
        }
        getCompanyCodesFromALE(services.aleDataService, setCompanyCodes, setLoadingCompanyCodes, setFetchCompanyCodesErrorMessage);
    }

    async function onSearchButtonClick() {
        // Clear current data on search page
        getRegistrationRecordsMap.clear()
        setCurrentPageIndex(0);
        setNextToken(emptyNextToken);

        if (registrationNumber.length !== 0) {
            await getRegistrationRecord(registrationNumber);
            return;
        }  
        if (approvalWorkflowStage.value !== "None") {
            await getRecordsByApprovalWorkflowStage(approvalWorkflowStage.value, emptyNextToken);
            return;
        }
        // Search by company codes
        await getRecordsByCompanyCodes(emptyNextToken);
    }

    async function getRegistrationRecord(registrationNumber: string) {
        setLoadingRegistrationRecords(true);
        const { fetchedRegistrationRecord, error } =
          await services.registrationDataService.getRegistrationRecord(registrationNumber);
        setLoadingRegistrationRecords(false);

        if (error) {
            services.messageService.showErrorAutoDismissBanner(error);
            return;
        }

        setSearchDone(true);
        history.push({
            pathname: '/show',
            state: fetchedRegistrationRecord
        });
    }

    async function getRecordsByApprovalWorkflowStage(approvalWorkflowStage: string, nextToken: string) {
        setLoadingRegistrationRecords(true);
        const { registrationRecords, nextPageToken, error } =
          await services.registrationDataService.getRecordsByApprovalWorkflowStage(approvalWorkflowStage, nextToken);
        setLoadingRegistrationRecords(false);

        handleSearchResponse(registrationRecords, nextPageToken, error);
    }

    async function getRecordsByCompanyCodes(nextToken: string) {
        setLoadingRegistrationRecords(true);
        const { registrationRecords, nextPageToken, error } =
          await services.registrationDataService.getRecordsByCompanyCodes(providerCompanyCode, recipientCompanyCode, nextToken);
        setLoadingRegistrationRecords(false);

        handleSearchResponse(registrationRecords, nextPageToken, error);
    }

    function handleSearchResponse(registrationRecords: RegistrationRecord[] | undefined, nextPageToken: string | undefined, error: string | undefined) {
        if (error) {
            services.messageService.showErrorAutoDismissBanner(error);
            return;
        }

        if (registrationRecords!.length > 0) {
            getRegistrationRecordsMap.set(getRegistrationRecordsMap.size + 1, registrationRecords!);
            setCurrentPageIndex(getRegistrationRecordsMap.size);
        }

        // Set next token if present for subsequent request
        if (nextPageToken) {
            setNextToken(nextPageToken)
        } else {
            setNextToken(emptyNextToken);
        }
        setSearchDone(true);
    }

    return (
      <div>
          <Form
            actions={
                <Button data-testid="search-records-submit-button" variant="primary" onClick={onSearchButtonClick}
                        disabled={registrationNumber.trim().length == 0
                          && providerCompanyCode.trim().length == 0
                          && recipientCompanyCode.trim().length == 0
                          && approvalWorkflowStage.value == "None"}>
                    Search
                </Button>
            }
            header={<Header variant="h1">Search Registration Records</Header>}
          >
              <ColumnLayout variant="text-grid" columns={4}>
                  <FormField label="Registration Number">
                      <Input data-testid="search-registration-number-input" value={registrationNumber}
                             onChange={event => setRegistrationNumber(event.detail.value)} placeholder="Enter registration number"
                             disabled={providerCompanyCode.trim().length != 0 || recipientCompanyCode.trim().length != 0 || approvalWorkflowStage.value !== "None"}
                             type="search" inputMode="search" />
                  </FormField>
                  <FormField label="Provider Company Code">
                      <Autosuggest data-testid="search-provider-company-code-input"
                                   value={providerCompanyCode}
                                   options={createLabelValueList(companyCodes)}
                                   onFocus={() => getCompanyCodes()}
                                   loadingText='Loading company codes'
                                   statusType={loadingCompanyCodes ? 'loading' : (fetchCompanyCodesErrorMessage ? 'error' : 'finished')}
                                   errorText={fetchCompanyCodesErrorMessage}
                                   onChange={({detail}) => setProviderCompanyCode(detail.value)}
                                   enteredTextLabel={value => `Use: "${value}"`}
                                   disabled={registrationNumber.trim().length != 0 || approvalWorkflowStage.value !== "None"}
                                   virtualScroll
                                   empty="No matches found"
                      />
                  </FormField>
                  <FormField label="Recipient Company Code">
                      <Autosuggest data-testid="search-recipient-company-code-input"
                                   value={recipientCompanyCode}
                                   options={createLabelValueList(companyCodes)}
                                   onFocus={() => getCompanyCodes()}
                                   loadingText='Loading company codes'
                                   statusType={loadingCompanyCodes ? 'loading' : (fetchCompanyCodesErrorMessage ? 'error' : 'finished')}
                                   errorText={fetchCompanyCodesErrorMessage}
                                   onChange={({detail}) => setRecipientCompanyCode(detail.value)}
                                   enteredTextLabel={value => `Use: "${value}"`}
                                   disabled={registrationNumber.trim().length != 0 || approvalWorkflowStage.value !== "None"}
                                   virtualScroll
                                   empty="No matches found"
                      />
                  </FormField>
                  <FormField label="Approval Workflow Stage">
                    <Select data-testid="approval-workflow-stage-input" selectedOption={approvalWorkflowStage} 
                      onChange={({detail: {selectedOption}}) => setApprovalWorkflowStage(selectedOption as SelectedOption)} 
                      options={[
                          {label: "None", value: "None"},
                          {label: stages.DRAFT, value: stages.DRAFT},
                          {label: stages.IN_ACCOUNTING_APPROVAL, value: stages.IN_ACCOUNTING_APPROVAL},
                          {label: stages.IN_TAX_APPROVAL, value: stages.IN_TAX_APPROVAL},
                          {label: stages.ACTIVE, value: stages.ACTIVE},
                          {label: stages.INACTIVE, value: stages.INACTIVE},
                          {label: stages.CANCELLED, value: stages.CANCELLED}
                      ]} placeholder="Choose a stage" disabled={registrationNumber.trim().length !== 0 ||
                      providerCompanyCode.trim().length !== 0 || recipientCompanyCode.trim().length !== 0}></Select>
                  </FormField>
                </ColumnLayout>
          </Form>

          <br/>
          <br/>

          {loadingRegistrationRecords ?
            <StatusIndicator type="loading"> Loading Registration Records </StatusIndicator> :
            <div>
                {searchDone ?
                  <div>
                      <Table data-testid="registration-records-table"
                             {...collectionProps}
                             columnDefinitions={[
                                 {
                                     id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.REGISTRATION_NUMBER,
                                     header: "Registration Number",
                                     cell: registrationRecord => <Link onFollow={() => getRegistrationRecord(registrationRecord.registrationNumber!)}>
                                         {registrationRecord.registrationNumber}
                                     </Link>,
                                     sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.REGISTRATION_NUMBER
                                 },
                                 {
                                    id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.APPROVAL_WORKFLOW_STAGE,
                                    header: "Approval Stage",
                                    cell: registrationRecord => registrationRecord.approvalWorkflowStage,
                                    sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.APPROVAL_WORKFLOW_STAGE
                                 },
                                 {
                                     id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.ATP_CALCULATION_FLAG,
                                     header: "ATP Calculation",
                                     cell: registrationRecord => String(registrationRecord.atpCalculationFlag),
                                     sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.ATP_CALCULATION_FLAG
                                 },
                                 {
                                     id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_PRODUCT_CATEGORY_NAME,
                                     header: "Tax Product Category Name",
                                     cell: registrationRecord => registrationRecord.taxProductCategoryName,
                                     sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_PRODUCT_CATEGORY_NAME
                                 },
                                 {
                                     id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_PRODUCT_NAME,
                                     header: "Tax Product Name",
                                     cell: registrationRecord => registrationRecord.taxProductName,
                                     sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_PRODUCT_NAME
                                 },
                                 {
                                     id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.ACCOUNTING_OWNER,
                                     header: "Accounting Owner",
                                     cell: registrationRecord => registrationRecord.accountingOwner,
                                     sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.ACCOUNTING_OWNER
                                 },
                                 {
                                     id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_OWNER,
                                     header: "Tax Owner",
                                     cell: registrationRecord => registrationRecord.taxOwner,
                                     sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_OWNER
                                 },
                                 {
                                     id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.PAYMENT_TERMS,
                                     header: "Payment Terms",
                                     cell: registrationRecord => registrationRecord.paymentTerms,
                                     sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.PAYMENT_TERMS
                                 },
                                 {
                                     id: Constants.GET_REGISTRATION_RECORDS_COLUMNS.LAST_UPDATED_DATE_TIME,
                                     header: "Last Updated Date Time",
                                     cell: registrationRecord => formatDate(new Date(registrationRecord.lastUpdatedDateTime!)),
                                     sortingField: Constants.GET_REGISTRATION_RECORDS_COLUMNS.LAST_UPDATED_DATE_TIME
                                 }
                             ]}
                             items={items}
                             resizableColumns
                             visibleColumns={[
                                 Constants.GET_REGISTRATION_RECORDS_COLUMNS.REGISTRATION_NUMBER,
                                 Constants.GET_REGISTRATION_RECORDS_COLUMNS.APPROVAL_WORKFLOW_STAGE,
                                 Constants.GET_REGISTRATION_RECORDS_COLUMNS.ATP_CALCULATION_FLAG,
                                 Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_PRODUCT_CATEGORY_NAME,
                                 Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_PRODUCT_NAME,
                                 Constants.GET_REGISTRATION_RECORDS_COLUMNS.ACCOUNTING_OWNER,
                                 Constants.GET_REGISTRATION_RECORDS_COLUMNS.TAX_OWNER,
                                 Constants.GET_REGISTRATION_RECORDS_COLUMNS.PAYMENT_TERMS,
                                 Constants.GET_REGISTRATION_RECORDS_COLUMNS.LAST_UPDATED_DATE_TIME,
                             ]}
                             empty={
                                 <Box textAlign="center" color="inherit"> No Registration data found </Box>
                             }
                             header={
                                 <>
                                     <Header>
                                         Registration Data
                                         <div>
                                             {paginationLinks}
                                         </div>
                                     </Header>
                                 </>
                             }
                      />
                      {nextToken ?
                        <div style={{float: 'right', margin:'15px 0 0 0'}}>
                            <Link data-testid="next-page-link" onFollow={() => {
                                approvalWorkflowStage.value !== 'None' ? 
                                    getRecordsByApprovalWorkflowStage(approvalWorkflowStage.value, nextToken) : getRecordsByCompanyCodes(nextToken);
                                }}> Next Page </Link>
                        </div> : <></>
                      }
                  </div>
                  : <></>
                }
            </div>
          }
      </div>
    );
}