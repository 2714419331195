import CONSTANTS from '../utils/constants';
import { ensureAuthenticated } from "src/utils/auth/authenticate";
import { RegistrationRecord } from "src/models/data-registration/RegistrationRecord";

// Common abstraction for calling any API. The parameters used for querying
// the API are all common among the API endpoints, so this method abstracts
// this functionality to make the API methods more readable.
async function _callExternalApi(method: string, url: string, body = null, headers = {}) {
    const auth = await ensureAuthenticated() as any;
    const session = auth.getSignInUserSession();
    const jwtToken = session.getIdToken().getJwtToken();

    // Utility function to check response status and handle errors based on status code
    async function checkStatus(response: any) {
        if (response.status >= 200 && response.status < 300) {
            // If everything looks good
            return;
        } else if (response.status >= 300 && response.status <= 500) {
            const responseBody = await response.json();
            throw new Error(responseBody.message);
        } else {
            // All other status codes (mostly 500s will display this generic message)
            throw new Error(CONSTANTS.GENERIC_ERROR_MESSAGE);
        }
    }

    const response = await fetch(url, {
        method,
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            ...headers,
            Authorization: jwtToken,
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: method === 'GET' ? null : JSON.stringify(body),
    });

    await checkStatus(response);
    return response;
}

async function _callBusinessApi(method: string, endpoint: string, body: any | undefined = null, headers = {}) {
    const baseUrl = CONSTANTS.ENVIRONMENT_VARIABLES.ICRS_API_GATEWAY_ARN as string;
    const url = `${baseUrl}${endpoint}`;

    return await _callExternalApi(method, url, body, headers);
}

const API = {
    async fetchRegistrationRecord(registrationNumber: string) {
        return await _callBusinessApi('GET', `/fetch/registrationRecord/${registrationNumber}`);
    },

    async createRegistrationRecord(registrationRecord: RegistrationRecord) {
        return await _callBusinessApi('POST', '/create/registrationRecord', registrationRecord);
    },

    async updateRegistrationRecord(registrationRecord: RegistrationRecord) {
        return await _callBusinessApi('POST', '/update/registrationRecord', registrationRecord);
    },

    async getRecordsByCompanyCodes(providerCompanyCode: string, recipientCompanyCode: string, pageSize?: number, nextToken?: string) {
        const queryParams = new URLSearchParams({
            providerCompanyCode,
            recipientCompanyCode
        });

        if (pageSize) {
            queryParams.append('pageSize', pageSize.toString());
        }

        if (nextToken) {
            queryParams.append('nextToken', nextToken);
        }

        const url = `/registrationRecords/byCompanyCodes?${queryParams.toString()}`;
        return await _callBusinessApi('GET', url);
    },

    async getRecordsByApprovalWorkflowStage(approvalWorkflowStage: string, pageSize?: number, nextToken?: string) {
        const queryParams = new URLSearchParams();

        if (pageSize) {
            queryParams.append('pageSize', pageSize.toString());
        }

        if (nextToken) {
            queryParams.append('nextToken', nextToken);
        }

        const queryString = queryParams.toString();
        const url = `/registrationRecords/byApprovalStage/${approvalWorkflowStage}?${queryString}`;
        return await _callBusinessApi('GET', url);
    },

    async getWorkflowRevisionRecord(baseRegistrationNumber: string, approvalWorkflowRev?: number, latestRev?: boolean) {
        const queryParams = new URLSearchParams({
            baseRegistrationNumber
        });

        if (approvalWorkflowRev !== undefined) {
            queryParams.append('approvalWorkflowRev', approvalWorkflowRev.toString());
        }

        if (latestRev !== undefined) {
            queryParams.append('latestRev', latestRev.toString());
        }

        const queryString = queryParams.toString();
        const url = `/workflowRev/registrationRecord?${queryString}`;
        return await _callBusinessApi('GET', url);
    },

    async discardWorkflowRevision(payload: any) {
        return await _callBusinessApi('POST', '/discard/workflowRev', payload);
    },

    async getMDMData() {
        return await _callBusinessApi('GET', '/tax-products/categories');
    },

    async getCompanyCodes() {
        return await _callBusinessApi('GET', '/aleData/companyCodes');
    },

    async getCompanyCodeData(companyCode: string) {
        return await _callBusinessApi('GET', `/aleData/companyCode/${companyCode}`);
    },

    async getCurrencyCodes() {
        return await _callBusinessApi('GET', '/currencyCodes');
    },
}

export default API;