import apiService from "src/services/ApiCallService";
import ErrorUtils from "src/utils/ErrorUtils";
import { GetCompanyCodeDataResponse } from "src/models/GetCompanyCodeDataResponse";
import { GetCompanyCodesResponse } from "src/models/GetCompanyCodesResponse";

export default class ALEDataService {

    /**
     * Fetches the list of company codes.
     * @returns An object containing an array of company codes or an error message if operation fails.
     */
    async getCompanyCodes() {
      let companyCodes: string[] | undefined = undefined;
      let error: string | undefined = undefined;

      try {
        const response = await apiService.getCompanyCodes();
        const data: GetCompanyCodesResponse = await response.json();
        companyCodes = data.companyCodes;
      } catch (ex) {
        error = ErrorUtils.getMessage(ex);
      }

      return {
        companyCodes,
        error
      };
    }

    /**
     * Fetches data for a specific company code.
     * @param companyCode - The company code to fetch data for.
     * @returns An object containing the company code data or an error message if operation fails.
     */
    async getCompanyCodeData(companyCode: string) {
      let companyCodeData: GetCompanyCodeDataResponse | undefined = undefined;
      let error: string | undefined = undefined;

      try {
        const response = await apiService.getCompanyCodeData(companyCode);
        companyCodeData = await response.json() as GetCompanyCodeDataResponse;
      } catch (ex) {
        error = ErrorUtils.getMessage(ex);
      }

      return {
        companyCodeData,
        error
      };
    }
  }
