import { Link, Popover } from "@amzn/awsui-components-react";
import React from "react";

export function customHelpPanel(message: string) {
    return (
        <Popover dismissButton={true}
                 position="right"
                 size="medium"
                 triggerType="custom"
                 content={<div> {message} </div>}
        >
            <Link variant="info">Info</Link>
        </Popover>
    );
}