import apiService from "src/services/ApiCallService";
import ErrorUtils from "src/utils/ErrorUtils";
import { GetCurrencyCodesResponse } from "src/models/GetCurrencyCodesResponse";

export default class CurrencyCodesService {

    /**
     * Fetches active currency codes list.
     * @returns An object containing the currency codes or error message if operation fails.
     */
    async getCurrencyCodes() {
        let currencyCodes: string[] | undefined = undefined;
        let error: string | undefined = undefined;

        try {
            const response = await apiService.getCurrencyCodes();
            const data: GetCurrencyCodesResponse = await response.json() as GetCurrencyCodesResponse;
            currencyCodes = data.currencyCodes
        } catch (ex) {
            error = ErrorUtils.getMessage(ex);
        }

        return {
            currencyCodes,
            error
        };
    }
}


