/**
 * Function to create an object with a label and value.
 *
 * @param value - the value in the object
 * @param label - optional label in the object, defaults to the value if not provided
 * @returns object with label and value
 */
export const createLabelValueObject = (value: string, label?: string) => {
  return {
    label: label ?? value,
    value: value,
  };
};

/**
 * Function to create a label-value list from an array of strings.
 *
 * @param items - list of string items to generate label-value objects from
 * @param getLabel - optional function to get the label from each item (defaults to the value if not provided)
 * @returns - list of objects with label and value properties
 */
export const createLabelValueList = (
    items: string[],
    getLabel?: (item: string) => string
) => {
  return items.map((item) => createLabelValueObject(item, getLabel?.(item)));
};

/**
 * Extracts the country name from a jurisdiction string.
 *
 * The jurisdiction string is expected to be in the format:
 * `CODE [Country/Region]`, e.g., `USTX000 [United States/Texas]`.
 *
 * This function will extract and return the country name (the part before the `/`)
 * within the square brackets. If no match is found, it returns an empty string.
 *
 * @param jurisdiction - The jurisdiction string containing the country and region information.
 * @returns The country name extracted from the jurisdiction string, or an empty string if not found.
 */
export const extractCountryFromJurisdiction = (jurisdiction: string): string => {
  const match = jurisdiction.match(/\[(.*?)\]/);
  return match ? match[1].split('/')[0].trim() : '';
};
