import React, { useContext } from "react";
import {
    Autosuggest,
    Button,
    ColumnLayout,
    DatePicker,
    ExpandableSection,
    Form,
    FormField,
    Header,
    Input,
    RadioGroup,
    StatusIndicator,
    Textarea
} from "@amzn/awsui-components-react";
import { areRecordsEqual, RegistrationRecord } from "src/models/data-registration/RegistrationRecord";
import ServiceCollection from "src/services/ServiceCollection";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalAppContext } from "src/components/App";
import CONSTANTS from "src/utils/constants";
import { formatDate } from "src/utils/DateUtils";
import { customHelpPanel } from "src/components/CustomComponents";
import { getCategoryDataFromMDM, getTaxProductCategoryNames, getTaxProductNames } from "src/utils/MDMUtils";
import { createLabelValueList } from "src/utils/commonComponentUtils";
import { fetchCurrencyCodes } from "src/utils/CurrencyCodesUtils";

interface EditRecordProps {
    services: ServiceCollection;
    userAlias: string;
    isAccountingUser: boolean;
    isTaxUser: boolean;
    isSuperUser: boolean;
}

export default function EditRecord(props: EditRecordProps) {
    const { isViewer, categoryNameToIdMapping, setCategoryNameToIdMapping,
        categorySubcategoriesMapping, setCategorySubcategoriesMapping, currencyCodes, setCurrencyCodes } = useContext(GlobalAppContext);
    const history = useHistory();

    // If user is viewer, redirect to home page
    if (isViewer) {
        history.push({
            pathname: '/',
        });
        return <></>;
    }
    const { services, userAlias, isAccountingUser, isTaxUser, isSuperUser } = props;
    const location = useLocation<any>();
    const currentRegistrationRecord = location.state as RegistrationRecord;
    const [loading, setIsLoading] = React.useState(false);

    // Registration Data fields
    const [atpCalculationFlag, setATPCalculationFlag] = React.useState(String(currentRegistrationRecord.atpCalculationFlag));
    const [currency, setCurrency] = React.useState(currentRegistrationRecord.currency ?? '');
    const [effectiveFromDate, setEffectiveFromDate] = React.useState(currentRegistrationRecord.effectiveFromDate ?? '');
    const [effectiveToDate, setEffectiveToDate] = React.useState(currentRegistrationRecord.effectiveToDate ?? '');
    const [workbookName, setWorkbookName] = React.useState(currentRegistrationRecord.workbookName ?? '');
    const [paymentTerms, setPaymentTerms] = React.useState(currentRegistrationRecord.paymentTerms ?? '');
    const [beat, setBeat] = React.useState(currentRegistrationRecord.beat ?? '');
    const [description, setDescription] = React.useState(currentRegistrationRecord.description ?? '');
    const [comments, setComments] = React.useState(currentRegistrationRecord.comments ?? '');
    const [providerEntityName, setProviderEntityName] = React.useState(currentRegistrationRecord.providerEntityName ?? '');
    const [recipientEntityName, setRecipientEntityName] = React.useState(currentRegistrationRecord.recipientEntityName ?? '');
    const [providerJurisdiction, setProviderJurisdiction] = React.useState(currentRegistrationRecord.providerJurisdiction ?? '');
    const [recipientJurisdiction, setRecipientJurisdiction] = React.useState(currentRegistrationRecord.recipientJurisdiction ?? '');
    const [providerTaxRegConsideration, setProviderTaxRegConsideration] = React.useState(String(currentRegistrationRecord.providerTaxRegConsideration));
    const [recipientTaxRegConsideration, setRecipientTaxRegConsideration] = React.useState(String(currentRegistrationRecord.recipientTaxRegConsideration));
    const [accountingOwner, setAccountingOwner] = React.useState(currentRegistrationRecord.accountingOwner ?? '');
    const [taxOwner, setTaxOwner] = React.useState(currentRegistrationRecord.taxOwner ?? '');
    const [taxProductId, setTaxProductId] = React.useState(currentRegistrationRecord.taxProductId ?? '');
    const [taxProductName, setTaxProductName] = React.useState(currentRegistrationRecord.taxProductName ?? '');
    const [taxProductCategoryId, setTaxProductCategoryId] = React.useState(currentRegistrationRecord.taxProductCategoryId ?? '');
    const [taxProductCategoryName, setTaxProductCategoryName] = React.useState(currentRegistrationRecord.taxProductCategoryName ?? '');
    const [exportOfServices, setExportOfServices] = React.useState(String(currentRegistrationRecord.exportOfServices));
    const [productNameToIdMapping, setProductNameToIdMapping] = React.useState(new Map());
    const [loadingMDMData, setLoadingMDMData] = React.useState(false);
    const [mdmResponseErrorMessage, setMDMResponseErrorMessage] = React.useState('');
    const [loadingCurrencyCodes, setLoadingCurrencyCodes] = React.useState(false);
    const [fetchCurrencyCodesErrorMessage, setFetchCurrencyCodesErrorMessage] = React.useState('');

    async function fetchCategorySubcategoriesData() {
        // If data is already loaded from React context, just return.
        if (categoryNameToIdMapping.size != 0 && categorySubcategoriesMapping.size != 0) {
            return;
        }
        getCategoryDataFromMDM(services.mdmDataService, setCategoryNameToIdMapping, setCategorySubcategoriesMapping, setLoadingMDMData, setMDMResponseErrorMessage);
    }

    function getCurrencyCodes() {
        // If currency codes are already loaded from React context, just return.
        if (currencyCodes.length != 0) {
            return;
        }
        fetchCurrencyCodes(services.currencyCodesService, setCurrencyCodes, setLoadingCurrencyCodes, setFetchCurrencyCodesErrorMessage);
    }

    function onTaxProductCategoryNameChange(taxProductCategoryName: string) {
        setTaxProductCategoryName(taxProductCategoryName);
        setTaxProductCategoryId(categoryNameToIdMapping.get(taxProductCategoryName) ?? '');
        setTaxProductName('');
        setTaxProductId('');
        if (categorySubcategoriesMapping.has(taxProductCategoryName)) {
            setProductNameToIdMapping(new Map(categorySubcategoriesMapping.get(taxProductCategoryName)!
                .map(taxProductSubcategory => [taxProductSubcategory.taxProductName, taxProductSubcategory.taxProductId])));
        } else {
            setProductNameToIdMapping(new Map<string, string>());
        }
    }

    function onTaxProductNameChange(taxProductName: string) {
        setTaxProductName(taxProductName);
        setTaxProductId(productNameToIdMapping.get(taxProductName) ?? '');
    }

    async function updateRegistrationRecord() {
        const registrationRecordToUpdate = getRegistrationRecordToUpdate();

        // If no change is made on edit page, show error message
        if (areRecordsEqual(currentRegistrationRecord, registrationRecordToUpdate)) {
            services.messageService.showErrorAutoDismissBanner(CONSTANTS.EDIT_PAGE_NO_CHANGE_ERROR_MESSAGE);
            return;
        }

        setIsLoading(true);
        const { updatedRegistrationRecord, error } =
            await services.registrationDataService.updateRegistrationRecord(registrationRecordToUpdate);
        setIsLoading(false);

        if (error) {
            services.messageService.showErrorAutoDismissBanner(error);
            return;
        }

        history.push({
            pathname: '/show',
            state: updatedRegistrationRecord
        });
    }

    function getRegistrationRecordToUpdate() {
        const registrationRecordToUpdate: RegistrationRecord = {
            baseRegistrationNumber: currentRegistrationRecord.baseRegistrationNumber,
            approvalWorkflowStage: CONSTANTS.APPROVAL_WORKFLOW_STAGES.DRAFT,
            atpCalculationFlag: JSON.parse(atpCalculationFlag),
            providerCompanyCode: currentRegistrationRecord.providerCompanyCode,
            providerEntityName: currentRegistrationRecord.providerEntityName,
            providerJurisdiction: currentRegistrationRecord.providerJurisdiction,
            recipientCompanyCode: currentRegistrationRecord.recipientCompanyCode,
            recipientEntityName: currentRegistrationRecord.recipientEntityName,
            recipientJurisdiction: currentRegistrationRecord.recipientJurisdiction,
            providerTaxRegConsideration: JSON.parse(providerTaxRegConsideration),
            recipientTaxRegConsideration: JSON.parse(recipientTaxRegConsideration),
            exportOfServices: JSON.parse(exportOfServices),
            lastUpdatedByAccountingUser: isAccountingUser || isSuperUser ? userAlias : currentRegistrationRecord.lastUpdatedByAccountingUser,
            lastUpdatedByTaxUser: isTaxUser || isSuperUser ? userAlias : currentRegistrationRecord.lastUpdatedByTaxUser,
            createdBy: currentRegistrationRecord.createdBy
        };

        registrationRecordToUpdate.paymentTerms = paymentTerms;
        if (currency) registrationRecordToUpdate.currency = currency;
        if (effectiveFromDate) registrationRecordToUpdate.effectiveFromDate = effectiveFromDate;
        if (effectiveToDate) registrationRecordToUpdate.effectiveToDate = effectiveToDate;
        if (workbookName) registrationRecordToUpdate.workbookName = workbookName;
        if (beat) registrationRecordToUpdate.beat = beat;
        if (description) registrationRecordToUpdate.description = description;
        if (comments) registrationRecordToUpdate.comments = comments;
        if (accountingOwner) registrationRecordToUpdate.accountingOwner = accountingOwner;
        if (taxOwner) registrationRecordToUpdate.taxOwner = taxOwner;
        if (taxProductName) registrationRecordToUpdate.taxProductName = taxProductName;
        if (taxProductId) registrationRecordToUpdate.taxProductId = taxProductId;
        if (taxProductCategoryName) registrationRecordToUpdate.taxProductCategoryName = taxProductCategoryName;
        if (taxProductCategoryId) registrationRecordToUpdate.taxProductCategoryId = taxProductCategoryId;

        return registrationRecordToUpdate;
    }

    return (
        <div>
            {loading ?
                <StatusIndicator type="loading"> Loading page </StatusIndicator> :
                <Form
                    actions={
                        <Button variant="primary"
                                data-testid="save-registration-record-button"
                                disabled={paymentTerms.trim().length == 0}
                                onClick={updateRegistrationRecord}>
                            Save
                        </Button>
                    }
                    header={<Header variant="h1">Update Registration Record</Header>}
                >
                    <div className="edit-registration-record" >
                        <ExpandableSection headerText="Registration Details" variant="container" defaultExpanded>
                            <ColumnLayout columns={2}>
                                <FormField label="ATP Calculation">
                                    <RadioGroup
                                      onChange={({ detail }) => setATPCalculationFlag(detail.value)}
                                      value={atpCalculationFlag}
                                      items={[
                                          { value: "true", label: "True" },
                                          { value: "false", label: "False" },
                                      ]}
                                    />
                                </FormField>
                                <FormField label="Currency">
                                    <Autosuggest data-class="input-text" data-testid="currency-input"
                                         value={currency}
                                         options={createLabelValueList(currencyCodes)}
                                         onFocus={() => getCurrencyCodes()}
                                         loadingText='Loading currency codes'
                                         statusType={loadingCurrencyCodes ? 'loading' : (fetchCurrencyCodesErrorMessage ? 'error' : 'finished')}
                                         errorText={fetchCurrencyCodesErrorMessage}
                                         onChange={({detail}) => setCurrency(detail.value)}
                                         onSelect={({detail}) => setCurrency(detail.value)}
                                         enteredTextLabel={value => `Use: "${value}"`}
                                         virtualScroll
                                         empty="No matches found"
                                    />
                                </FormField>
                                <FormField label="Effective From Date" constraintText="Use YYYY/MM/DD format.">
                                    <DatePicker
                                        value={effectiveFromDate}
                                        nextMonthAriaLabel="Next month"
                                        placeholder="YYYY/MM/DD"
                                        previousMonthAriaLabel="Previous month"
                                        todayAriaLabel="Today"
                                        onChange={({ detail }) => setEffectiveFromDate(detail.value)}
                                    />
                                </FormField>
                                <FormField label="Effective To Date" constraintText="Use YYYY/MM/DD format.">
                                    <DatePicker
                                        value={effectiveToDate}
                                        nextMonthAriaLabel="Next month"
                                        placeholder="YYYY/MM/DD"
                                        previousMonthAriaLabel="Previous month"
                                        todayAriaLabel="Today"
                                        onChange={({ detail }) => setEffectiveToDate(detail.value)}
                                    />
                                </FormField>
                                <FormField label="Workbook Name" info={customHelpPanel(CONSTANTS.FIELDS_INFO.WORKBOOK_NAME)}>
                                    <Input data-class="input-text" value={workbookName}
                                           onChange={({ detail }) => setWorkbookName(detail.value)} />
                                </FormField>
                                <FormField label="Payment Terms*" info={customHelpPanel(CONSTANTS.FIELDS_INFO.PAYMENT_TERMS)}
                                           constraintText={paymentTerms.trim().length == 0 ? 'required': ''}>
                                    <Autosuggest data-class="input-text" data-testid="payment-terms-input"
                                         value={paymentTerms}
                                         options={createLabelValueList(CONSTANTS.PAYMENT_TERMS)}
                                         onChange={({detail}) => setPaymentTerms(detail.value)}
                                         onSelect={({detail}) => setPaymentTerms(detail.value)}
                                         enteredTextLabel={value => `Use: "${value}"`}
                                         virtualScroll
                                         empty="No matches found"
                                    />
                                </FormField>
                                <FormField label="Beat" info={customHelpPanel(CONSTANTS.FIELDS_INFO.BEAT)}>
                                    <Input data-class="input-text" value={beat}
                                           onChange={({ detail }) => setBeat(detail.value)} />
                                </FormField>
                                <FormField />
                                <FormField label="Description">
                                    <Textarea data-class="input-text" value={description}
                                           onChange={({ detail }) => setDescription(detail.value)} />
                                </FormField>
                                <FormField label="Comments" info={customHelpPanel(CONSTANTS.FIELDS_INFO.COMMENTS)}>
                                    <Textarea data-class="input-text" data-testid="comments-input" value={comments}
                                           onChange={({ detail }) => setComments(detail.value)} />
                                </FormField>
                            </ColumnLayout>
                        </ExpandableSection>
                        <ExpandableSection headerText="Party Information" variant="container" defaultExpanded>
                            <ColumnLayout columns={2}>
                                <FormField label="Provider Company Code*" info={customHelpPanel(CONSTANTS.FIELDS_INFO.PROVIDER_COMPANY_CODE)}>
                                    <Input data-class="input-text" value={currentRegistrationRecord.providerCompanyCode} disabled />
                                </FormField>
                                <FormField label="Recipient Company Code*" info={customHelpPanel(CONSTANTS.FIELDS_INFO.RECIPIENT_COMPANY_CODE)}>
                                    <Input data-class="input-text" value={currentRegistrationRecord.recipientCompanyCode} disabled />
                                </FormField>
                                <FormField label="Provider Entity Name">
                                    <Input data-class="input-text" value={providerEntityName}
                                           onChange={({ detail }) => setProviderEntityName(detail.value)} disabled />
                                </FormField>
                                <FormField label="Recipient Entity Name">
                                    <Input data-class="input-text" value={recipientEntityName}
                                           onChange={({ detail }) => setRecipientEntityName(detail.value)} disabled />
                                </FormField>
                                <FormField label="Provider Jurisdiction">
                                    <Input data-class="input-text" value={providerJurisdiction}
                                           onChange={({ detail }) => setProviderJurisdiction(detail.value)} disabled />
                                </FormField>
                                <FormField label="Recipient Jurisdiction">
                                    <Input data-class="input-text" value={recipientJurisdiction}
                                           onChange={({ detail }) => setRecipientJurisdiction(detail.value)} disabled />
                                </FormField>
                            </ColumnLayout>
                        </ExpandableSection>
                        <ExpandableSection headerText="Registration Data Contributors" variant="container" defaultExpanded>
                            <ColumnLayout columns={2}>
                                <FormField label="Accounting Owner">
                                    <Input data-class="input-text" value={accountingOwner}
                                           onChange={({ detail }) => setAccountingOwner(detail.value)} />
                                </FormField>
                                <FormField label="Tax Owner">
                                    <Input data-class="input-text" value={taxOwner}
                                           onChange={({ detail }) => setTaxOwner(detail.value)} />
                                </FormField>
                                <FormField label="Created By">
                                    <Input data-class="input-text" value={currentRegistrationRecord.createdBy} disabled />
                                </FormField>
                                <FormField label="Created Date Time">
                                    <Input data-class="input-text" value={formatDate(new Date(currentRegistrationRecord.createDateTime!))} disabled />
                                </FormField>
                            </ColumnLayout>
                        </ExpandableSection>
                        <ExpandableSection headerText="Tax Product Details" variant="container" defaultExpanded>
                            <ColumnLayout columns={2}>
                                <FormField label="Provider Tax Reg Consideration">
                                    <RadioGroup
                                        onChange={({ detail }) => setProviderTaxRegConsideration(detail.value)}
                                        value={providerTaxRegConsideration}
                                        items={[
                                            { value: "true", label: "True" },
                                            { value: "false", label: "False" },
                                        ]}
                                    />
                                </FormField>
                                <FormField label="Recipient Tax Reg Consideration">
                                    <RadioGroup
                                        onChange={({ detail }) => setRecipientTaxRegConsideration(detail.value)}
                                        value={recipientTaxRegConsideration}
                                        items={[
                                            { value: "true", label: "True" },
                                            { value: "false", label: "False" },
                                        ]}
                                    />
                                </FormField>
                                <FormField label="Tax Product Category Name">
                                    <Autosuggest data-class="input-text" data-testid="tax-product-category-name-input"
                                         value={taxProductCategoryName}
                                         options={getTaxProductCategoryNames(categoryNameToIdMapping)}
                                         onFocus={() => fetchCategorySubcategoriesData()}
                                         loadingText='Loading tax product category names'
                                         statusType={loadingMDMData ? 'loading' : (mdmResponseErrorMessage ? 'error' : 'finished')}
                                         errorText={mdmResponseErrorMessage}
                                         onChange={({detail}) => onTaxProductCategoryNameChange(detail.value)}
                                         enteredTextLabel={value => `Use: "${value}"`}
                                         empty="No matches found"
                                    />
                                </FormField>
                                <FormField label="Tax Product Category Id">
                                    <Input data-class="input-text" value={taxProductCategoryId}
                                           onChange={({detail}) => setTaxProductCategoryId(detail.value)}/>
                                </FormField>
                                <FormField label="Tax Product Name">
                                    <Autosuggest data-class="input-text"
                                         value={taxProductName}
                                         options={getTaxProductNames(productNameToIdMapping)}
                                         onChange={({detail}) => onTaxProductNameChange(detail.value)}
                                         enteredTextLabel={value => `Use: "${value}"`}
                                         empty="No matches found"
                                    />
                                </FormField>
                                <FormField label="Tax Product Id">
                                    <Input data-class="input-text" value={taxProductId}
                                           onChange={({detail}) => setTaxProductId(detail.value)}/>
                                </FormField>
                                <FormField label="Export of Services" info={customHelpPanel(CONSTANTS.FIELDS_INFO.EXPORT_OF_SERVICES)}>
                                    <RadioGroup
                                        onChange={({ detail }) => setExportOfServices(detail.value)}
                                        value={exportOfServices}
                                        items={[
                                            { value: "true", label: "True" },
                                            { value: "false", label: "False" },
                                        ]}
                                    />
                                </FormField>
                            </ColumnLayout>
                        </ExpandableSection>
                    </div>
                </Form>
            }
        </div>
    );
}