import { Dispatch, SetStateAction } from "react";
import CurrencyCodesService from "src/services/CurrencyCodesService";

/**
 * Function to fetch currency codes from the service and store them in React context.
 *
 * @param currencyCodesService - service instance used to fetch currency codes.
 * @param setCurrencyCodes - React state setter to update the list of currency codes in context.
 * @param setLoadingCurrencyCodes - React state setter to toggle the loading state while fetching currency codes.
 * @param setFetchCurrencyCodesErrorMessage - React state setter to update error messages in case of a fetch failure.
 */
export const fetchCurrencyCodes = async (
    currencyCodesService: CurrencyCodesService,
    setCurrencyCodes: Dispatch<SetStateAction<string[]>>,
    setLoadingCurrencyCodes: Dispatch<SetStateAction<boolean>>,
    setFetchCurrencyCodesErrorMessage: Dispatch<SetStateAction<string>>
) => {
    setLoadingCurrencyCodes(true);
    const { currencyCodes, error } = await currencyCodesService.getCurrencyCodes();

    if (error) {
        setFetchCurrencyCodesErrorMessage(error);
        return;
    }

    // Load currency codes into React context
    setCurrencyCodes(currencyCodes!);
    setLoadingCurrencyCodes(false);
};
