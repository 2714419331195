import { createLabelValueObject } from "src/utils/commonComponentUtils";
import { Dispatch, SetStateAction } from "react";
import MDMDataService from "src/services/MDMDataService";
import { Subcategory } from "src/models/GetCategorySubcategoriesDataResponse";

/**
 * Function to get the list of tax product category names
 * @param categoryNameToIdMapping - category name to id mapping
 * @returns - category[]
 */
export const getTaxProductCategoryNames = (categoryNameToIdMapping: Map<string, string>) => {
    const taxProductCategoryNames = [];
    for (const key of categoryNameToIdMapping.keys()) {
        taxProductCategoryNames.push(createLabelValueObject(key));
    }
    return taxProductCategoryNames;
};


/**
 * Function to get the list of tax product names
 * @param productNameToIdMapping - product name to id mapping
 * @returns - taxProductNames[]
 */
export const getTaxProductNames = (productNameToIdMapping: Map<string, string>) => {
    const taxProductNames = [];
    for (const key of productNameToIdMapping.keys()) {
        taxProductNames.push(createLabelValueObject(key));
    }
    return taxProductNames;
};

/**
 * Function to fetch category and subcategory mappings from MDM service if not already present in the React context.
 *
 * @param mdmDataService - service to fetch MDM category and subcategory data.
 * @param setCategoryNameToIdMapping - React state setter to update the category name to ID mapping.
 * @param setCategorySubcategoriesMapping - React state setter to update the category to subcategories mapping.
 * @param setLoadingMDMData - React state setter to toggle loading state for MDM data.
 * @param setMDMResponseErrorMessage - React state setter to handle error messages from the MDM response.
 */
export const getCategoryDataFromMDM = async (
    mdmDataService: MDMDataService,
    setCategoryNameToIdMapping: Dispatch<SetStateAction<Map<string, string>>>,
    setCategorySubcategoriesMapping: Dispatch<SetStateAction<Map<string, Subcategory[]>>>,
    setLoadingMDMData: Dispatch<SetStateAction<boolean>>,
    setMDMResponseErrorMessage: Dispatch<SetStateAction<string>>
) => {
    setLoadingMDMData(true);
    const { categorySubcategoriesData, error } = await mdmDataService.getCategorySubcategoriesData();

    if (error) {
        setMDMResponseErrorMessage(error);
        return;
    }

    // Update React context with the latest MDM data
    setCategoryNameToIdMapping(new Map(Object.entries(categorySubcategoriesData!.categoryNameToIdMapping)));
    setCategorySubcategoriesMapping(new Map(Object.entries(categorySubcategoriesData!.categorySubcategoriesMapping)));

    setLoadingMDMData(false);
};
