import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import '@amzn/awsui-global-styles/polaris.css';
import App from "src/components/App";
import ServiceCollection from "src/services/ServiceCollection";
import { ensureAuthenticated, getUserProfileFromCognitoSession } from "src/utils/auth/authenticate";
import AppRestrict from "src/components/AppRestrict";
import CONSTANTS from "src/utils/constants";
import AWS from 'aws-sdk';

function startApp() {
    ensureAuthenticated()
        .then((auth: any) => {
            AWS.config.update({
                region: CONSTANTS.ENVIRONMENT_VARIABLES.AWS_REGION,
            });
            const session = auth.getSignInUserSession();
            const userProfile = getUserProfileFromCognitoSession(session);
            const jwtToken = session.getIdToken().getJwtToken();
            const loginProvider = `cognito-idp.${CONSTANTS.ENVIRONMENT_VARIABLES.AWS_REGION}.amazonaws.com/${CONSTANTS.ENVIRONMENT_VARIABLES.USER_POOL_ID}`;
            const credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: CONSTANTS.ENVIRONMENT_VARIABLES.IDENTITY_POOL_ID,
                Logins: {
                    [loginProvider]: jwtToken,
                },
            });
            AWS.config.update({
                credentials: credentials
            });
            (AWS.config.credentials as AWS.Credentials)?.get(() => {
                console.log("AWS Credentials are set!");
            })
            ReactDOM.render(
                <Suspense fallback={<Spinner size='large' />}>
                    <BrowserRouter>
                        <App services={new ServiceCollection()} userProfile={userProfile} />
                    </BrowserRouter>
                </Suspense>,
                document.getElementById('root')
            );
        })
        .catch((e: any) => {
            console.log('ensureAuthenticated threw an exception: ', e);
            ReactDOM.render(
                <BrowserRouter>
                    <AppRestrict />
                </BrowserRouter>,
                document.getElementById('root'),
            );
        });
}

startApp();
