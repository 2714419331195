import React from "react";

import { RegistrationRecord } from "src/models/data-registration/RegistrationRecord";
import CONSTANTS from "src/utils/constants";
import { Steps } from 'antd';

interface ApprovalWorkflowProps {
    currentUser: string;
    registrationRecord: RegistrationRecord;
}


export default function ApprovalWorkflow({currentUser, registrationRecord}: ApprovalWorkflowProps) {

    interface StepItem  {
        title: string,
        description: string,
        status: 'process' | 'finish' | 'wait' | 'error' | undefined,
        disabled: boolean
    }

    let currentStage: number;

    let stages = CONSTANTS.APPROVAL_WORKFLOW_STAGES;

    const stepItems: StepItem[] = [
        { title: stages.DRAFT, description: '', status: undefined, disabled: true },
        { title: stages.IN_ACCOUNTING_APPROVAL, description: '',status: undefined, disabled: true },
        { title: stages.IN_TAX_APPROVAL, description: '', status: undefined, disabled: true },
        { title: stages.ACTIVE, description: '', status: undefined, disabled: true },
        { title: stages.INACTIVE, description: '', status: undefined, disabled: true },
        { title: stages.CANCELLED, description: '',status: undefined, disabled: true }
    ];

    switch(registrationRecord.approvalWorkflowStage) {
        case stages.DRAFT: {
            currentStage = 0;
            stepItems[0].description = 'Last Updated By: ' + currentUser;
            break;
        }
        case stages.IN_ACCOUNTING_APPROVAL: {
            currentStage = 1;
            stepItems[0].description = 'Updated By: ' + currentUser;
            if (registrationRecord.taxApprover) {
                stepItems[2].description = 'Approved By: ' + registrationRecord.taxApprover;
                stepItems[2].status = 'finish';
            }
            break;
        }
        case stages.IN_TAX_APPROVAL: {
            currentStage = 2;
            stepItems[0].description = 'Last Updated By: ' + currentUser;
            stepItems[1].description = 'Approved By: ' + registrationRecord.accountingApprover!;
            break;
        }
        case stages.ACTIVE: {
            currentStage = 3;
            stepItems[0].description = 'Last Updated By: ' + currentUser;
            stepItems[1].description = 'Approved By: ' + registrationRecord.accountingApprover!;
            stepItems[2].description = 'Approved By: ' + registrationRecord.taxApprover!;
            if (registrationRecord.reactivatedBy) {
                stepItems[3].description = 'Reactivated By: ' + registrationRecord.reactivatedBy;
            }
            break;
        }
        case stages.INACTIVE: {
            currentStage = 4;
            stepItems[0].description = 'Last Updated By: ' + currentUser;
            stepItems[1].description = 'Approved By: ' + registrationRecord.accountingApprover!
            stepItems[2].description = 'Approved By: ' + registrationRecord.taxApprover!;
            stepItems[4].description = 'Deactivated By: ' + registrationRecord.deactivatedBy!;
            stepItems[3].status = 'wait';
            break;
        }
        case stages.CANCELLED: {
            currentStage = 5;
            stepItems[0].description = 'Last Updated By: ' + currentUser;
            stepItems[1].description = registrationRecord.accountingApprover ? 'Approved By: ' + registrationRecord.accountingApprover! : '';
            stepItems[2].description = registrationRecord.taxApprover ? 'Approved By: ' + registrationRecord.taxApprover! : '';
            stepItems[5].description = 'By: ' + registrationRecord.cancelledBy!;
            stepItems[3].status = 'wait';
            stepItems[4].status = 'wait';
            stepItems[1].status = registrationRecord.accountingApprover ? 'finish' : 'wait';
            stepItems[2].status = registrationRecord.taxApprover ? 'finish' : 'wait';
            break;
        }
        default: {
            break;
        }
    }

    return <Steps current={currentStage!} direction='horizontal' labelPlacement='horizontal' type='default' items={stepItems} size='default'></Steps>;
}

