const ErrorUtils = {
    // TODO: need to replace with invoking logging service in future
    getMessage(error: unknown) {
        if (error instanceof Error) {
            return error.message;
        }
        return String(error);
    },
};

export default ErrorUtils;