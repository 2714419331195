import React, { Dispatch, SetStateAction } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import SearchRecords from "src/components/data-registration/SearchRecords";
import CustomAppLayout from "src/components/CustomAppLayout";
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import ICRSAction from "src/models/ICRSAction";
import { GlobalState, initialState } from "src/services/global/GlobalState";
import { GLOBAL_ACTIONS, globalReducer } from "src/services/global/GlobalReducer";
import ServiceCollection from "src/services/ServiceCollection";
import { UserProfile } from "src/models/UserProfile";
import CONSTANTS from "src/utils/constants";
import CreateRecord from "src/components/data-registration/CreateRecord";
import ShowRecord from "src/components/data-registration/ShowRecord";
import EditRecord from "src/components/data-registration/EditRecord";
import { Subcategory } from "src/models/GetCategorySubcategoriesDataResponse";

/**
 * Defines the properties available inside the global context
 */
export type GlobalContextType = {
    globalState: GlobalState,
    globalDispatch: React.Dispatch<ICRSAction>,
    isViewer: boolean,
    // MDM props
    categoryNameToIdMapping: Map<string, string>,
    setCategoryNameToIdMapping: Dispatch<SetStateAction<Map<string, string>>>,
    categorySubcategoriesMapping: Map<string, Subcategory[]>,
    setCategorySubcategoriesMapping: Dispatch<SetStateAction<Map<string, Subcategory[]>>>,
    // ALE props
    companyCodes: string[],
    setCompanyCodes: Dispatch<SetStateAction<string[]>>,
    // Currency Codes
    currencyCodes: string[],
    setCurrencyCodes: Dispatch<SetStateAction<string[]>>
}

/**
 * It takes care of "injecting" the context value in the children
 * @param props Context provider props
 * @returns The children of this functional component wrapped with the ContextProvided value
 */
const GlobalContextProvider = (props: any) => {
    const { state, dispatch, isViewer, categoryNameToIdMapping, setCategoryNameToIdMapping, categorySubcategoriesMapping,
        setCategorySubcategoriesMapping, companyCodes, setCompanyCodes, currencyCodes, setCurrencyCodes, children } = props;
    const providerValue = React.useMemo(() => ({
        globalState: state,
        globalDispatch: dispatch,
        isViewer: isViewer,
        categoryNameToIdMapping: categoryNameToIdMapping,
        setCategoryNameToIdMapping: setCategoryNameToIdMapping,
        categorySubcategoriesMapping: categorySubcategoriesMapping,
        setCategorySubcategoriesMapping: setCategorySubcategoriesMapping,
        companyCodes: companyCodes,
        setCompanyCodes: setCompanyCodes,
        currencyCodes: currencyCodes,
        setCurrencyCodes: setCurrencyCodes
    }), [state, dispatch, isViewer, categoryNameToIdMapping, setCategoryNameToIdMapping, categorySubcategoriesMapping,
        setCategorySubcategoriesMapping, companyCodes, setCompanyCodes, currencyCodes, setCurrencyCodes]);
    return (
        <GlobalAppContext.Provider value={providerValue}>
            {children}
        </GlobalAppContext.Provider>
    );
}
/**
 * The global context being shared to any component that needs it
 */
export const GlobalAppContext = React.createContext(null as unknown as GlobalContextType);

export default function App(props: { services: ServiceCollection, userProfile: UserProfile }) {
    const { services, userProfile } = props;

    // A user is a viewer if it only has view permissions
    const isViewer: boolean = userProfile.roles.length === 1 && userProfile.roles[0] === CONSTANTS.ACCESS_ROLES.VIEWER;
    const isTaxUser: boolean = userProfile.roles.includes(CONSTANTS.ACCESS_ROLES.TAX_USER);
    const isAccountingUser: boolean = userProfile.roles.includes(CONSTANTS.ACCESS_ROLES.ACCOUNTING_USER);
    const isSuperUser: boolean = userProfile.roles.includes(CONSTANTS.ACCESS_ROLES.ADMIN)
    const userAlias: string = userProfile.alias;

    const [globalState, globalDispatch] = useReducerWithLogger(globalReducer, initialState);
    services.messageService.setupForBanner(globalDispatch, GLOBAL_ACTIONS.ADD_BANNER_MESSAGE, GLOBAL_ACTIONS.REMOVE_BANNER_MESSAGE);

    // MDM props
    const [categoryNameToIdMapping, setCategoryNameToIdMapping] = React.useState(new Map<string, string>());
    const [categorySubcategoriesMapping, setCategorySubcategoriesMapping] = React.useState(new Map<string, Subcategory[]>());

    // ALE props
    const[companyCodes, setCompanyCodes] = React.useState([]);

    // Currency Codes
    const[currencyCodes, setCurrencyCodes] = React.useState([]);

    return (
        <GlobalContextProvider state={globalState}
                               dispatch={globalDispatch}
                               isViewer={isViewer}
                               categoryNameToIdMapping={categoryNameToIdMapping}
                               setCategoryNameToIdMapping={setCategoryNameToIdMapping}
                               categorySubcategoriesMapping={categorySubcategoriesMapping}
                               setCategorySubcategoriesMapping={setCategorySubcategoriesMapping}
                               companyCodes={companyCodes}
                               setCompanyCodes={setCompanyCodes}
                               currencyCodes={currencyCodes}
                               setCurrencyCodes={setCurrencyCodes}
        >
            <CustomAppLayout userProfile={userProfile} >
                <Switch>
                    <Route exact path="/">
                        <SearchRecords services={services} />
                    </Route>
                    { !isViewer &&
                        <Route exact path="/create">
                            <CreateRecord services={services}
                                          userAlias={userProfile.alias}
                                          isAccountingUser={isAccountingUser}
                                          isTaxUser={isTaxUser}
                                          isSuperUser={isSuperUser}
                            />
                        </Route>
                    }
                    <Route exact path="/show">
                        <ShowRecord services={services}
                                    userAlias={userAlias}
                                    isAccountingUser={isAccountingUser}
                                    isTaxUser={isTaxUser}
                                    isSuperUser={isSuperUser}
                        />
                    </Route>
                    <Route exact path="/edit">
                        <EditRecord services={services}
                                    userAlias={userProfile.alias}
                                    isAccountingUser={isAccountingUser}
                                    isTaxUser={isTaxUser}
                                    isSuperUser={isSuperUser}
                        />
                    </Route>
                    <Redirect path="*" to="/" />
                </Switch>
            </CustomAppLayout>
        </GlobalContextProvider>
    );
}