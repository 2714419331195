import RegistrationDataService from "src/services/RegistrationDataService";
import MessageService from "src/services/MessageService";
import MDMDataService from "src/services/MDMDataService";
import ALEDataService from "src/services/ALEDataService";
import CurrencyCodesService from "src/services/CurrencyCodesService";

/**
 * Use this class to add services that you want to be available to a context
 */
export default class ServiceCollection {
    registrationDataService: RegistrationDataService;
    messageService: MessageService;
    mdmDataService: MDMDataService;
    aleDataService: ALEDataService;
    currencyCodesService: CurrencyCodesService;

    constructor() {
        this.registrationDataService = new RegistrationDataService();
        this.messageService = new MessageService();
        this.mdmDataService = new MDMDataService();
        this.aleDataService = new ALEDataService();
        this.currencyCodesService = new CurrencyCodesService();
    }
}