import apiService from "src/services/ApiCallService";
import ErrorUtils from "src/utils/ErrorUtils";
import { GetCategorySubcategoriesDataResponse } from "src/models/GetCategorySubcategoriesDataResponse";

export default class MDMDataService {

    /**
     * Fetches category and subcategory data from the MDM service.
     * @returns An object containing the category and subcategories data or error message if operation fails.
     */
    async getCategorySubcategoriesData() {
        let categorySubcategoriesData: GetCategorySubcategoriesDataResponse | undefined = undefined;
        let error: string | undefined = undefined;

        try {
            const response = await apiService.getMDMData();
            categorySubcategoriesData = await response.json() as GetCategorySubcategoriesDataResponse;
        } catch (ex) {
            error = ErrorUtils.getMessage(ex);
        }

        return {
            categorySubcategoriesData,
            error
        };
    }
}


