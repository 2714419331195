import React from "react";
import { Button, SpaceBetween } from "@amzn/awsui-components-react";
import CONSTANTS from "src/utils/constants";
import { RegistrationRecord } from "src/models/data-registration/RegistrationRecord";

interface ShowApprovalWorkflowActionsProps {
    registrationRecord: RegistrationRecord;
    stage: string | undefined;
    isViewer: boolean;
    isAccountingUser: boolean;
    isTaxUser: boolean;
    isSuperUser: boolean;
    userAlias: string;
    setEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    goToEditPage: () => void;
    setDiscardModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    submitForApproval: () => void;
    accountingApprove: () => void;
    taxApprove: () => void;
    deactivate: () => void;
    setActivateModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setCancelModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ShowApprovalWorkflowActions({
                                                        registrationRecord,
                                                        stage,
                                                        isViewer,
                                                        isAccountingUser,
                                                        isTaxUser,
                                                        isSuperUser,
                                                        userAlias,
                                                        setEditModalVisible,
                                                        goToEditPage,
                                                        setDiscardModalVisible,
                                                        submitForApproval,
                                                        accountingApprove,
                                                        taxApprove,
                                                        deactivate,
                                                        setActivateModalVisible,
                                                        setCancelModalVisible
                                                    }: ShowApprovalWorkflowActionsProps) {
    if (isViewer || !registrationRecord.latestRev) {
        return null;
    }

    const { DRAFT, IN_ACCOUNTING_APPROVAL, IN_TAX_APPROVAL, ACTIVE, INACTIVE, CANCELLED } = CONSTANTS.APPROVAL_WORKFLOW_STAGES;

    const renderEditButton = () => (
        stage !== INACTIVE && stage !== CANCELLED && (
            <Button
                data-testid="go-to-edit-page-button"
                variant="normal"
                onClick={() => (stage === CONSTANTS.APPROVAL_WORKFLOW_STAGES.ACTIVE ? setEditModalVisible(true) : goToEditPage())}
            >
                Edit
            </Button>
        )
    );

    const renderDiscardButton = () => (
        (stage === DRAFT || stage === IN_ACCOUNTING_APPROVAL || stage === IN_TAX_APPROVAL) && (
            <Button
                data-testid="discard-revision-button"
                variant="primary"
                onClick={() => setDiscardModalVisible(true)}
            >
                Discard Version
            </Button>
        )
    );

    const renderSubmitForApprovalButton = () => (
        stage === DRAFT && (
            <Button
                data-testid="submit-for-approval-button"
                variant="primary"
                onClick={submitForApproval}
            >
                Submit for Approval
            </Button>
        )
    );

    const renderAccountingApproveButton = () => (
        stage === IN_ACCOUNTING_APPROVAL && ((isAccountingUser && userAlias !== registrationRecord.lastUpdatedByAccountingUser) || isSuperUser) && (
            <Button
                data-testid="accounting-approve-button"
                variant="primary"
                onClick={accountingApprove}
            >
                Approve as Accounting
            </Button>
        )
    );

    const renderTaxApproveButton = () => (
        ((stage === IN_ACCOUNTING_APPROVAL && !registrationRecord.taxApprover) || stage === IN_TAX_APPROVAL)
        && ((isTaxUser && userAlias !== registrationRecord.lastUpdatedByTaxUser) || isSuperUser) && (
            <Button
                data-testid="tax-approve-button"
                variant="primary"
                onClick={taxApprove}
            >
                Approve as Tax
            </Button>
        )
    );

    const renderDeactivateButton = () => (
        stage === ACTIVE && (
            <Button
                data-testid="deactivate-button"
                variant="primary"
                onClick={deactivate}
            >
                Deactivate
            </Button>
        )
    );

    const renderActivateButton = () => (
        stage === INACTIVE && (
            <Button
                data-testid="activate-button"
                variant="primary"
                onClick={() => setActivateModalVisible(true)}
            >
                Reactivate
            </Button>
        )
    );

    const renderCancelButton = () => (
        (stage === ACTIVE || stage === INACTIVE) && (
            <Button
                data-testid="cancel-button"
                variant="primary"
                onClick={() => setCancelModalVisible(true)}
            >
                Cancel Registration Record
            </Button>
        )
    );

    return (
        <div data-class="actions">
            <SpaceBetween direction="horizontal" size="xs">
                {renderEditButton()}
                {renderDiscardButton()}
                {renderSubmitForApprovalButton()}
                {renderAccountingApproveButton()}
                {renderTaxApproveButton()}
                {renderDeactivateButton()}
                {renderActivateButton()}
                {renderCancelButton()}
            </SpaceBetween>
        </div>
    );
}
