export interface RegistrationRecord {
    registrationNumber?: string;
    baseRegistrationNumber?: string;
    approvalWorkflowStage?: string;
    approvalWorkflowRev?: number;
    latestRev?: boolean;
    lastApprovedRev?: boolean;
    atpCalculationFlag: boolean;
    currency?: string;
    effectiveFromDate?: string;
    effectiveToDate?: string;
    workbookName?: string;
    beat?: string;
    description?: string;
    comments?: string;
    paymentTerms?: string;
    providerCompanyCode: string;
    providerEntityName?: string;
    providerJurisdiction?: string;
    recipientCompanyCode: string;
    recipientEntityName?: string;
    recipientJurisdiction?: string;
    providerTaxRegConsideration: boolean;
    recipientTaxRegConsideration: boolean;
    accountingOwner?: string;
    taxOwner?: string;
    taxProductId?: string;
    taxProductName?: string;
    taxProductCategoryId?: string;
    taxProductCategoryName?: string;
    exportOfServices: boolean;
    accountingApprover?: string;
    taxApprover?: string;
    cancelledBy?: string;
    deactivatedBy?: string;
    reactivatedBy?: string;
    createdBy: string;
    lastUpdatedByAccountingUser?: string;
    lastUpdatedByTaxUser?: string;
    createDateTime?: string;
    lastUpdatedDateTime?: string;
}

export function areRecordsEqual(firstRegistrationRecord: RegistrationRecord, secondRegistrationRecord: RegistrationRecord) {
    const fieldsToExclude = new Set<keyof RegistrationRecord>([
        'registrationNumber',
        'baseRegistrationNumber',
        'approvalWorkflowStage',
        'approvalWorkflowRev',
        'latestRev',
        'lastApprovedRev',
        'createdBy',
        'lastUpdatedByAccountingUser',
        'lastUpdatedByTaxUser',
        'createDateTime',
        'lastUpdatedDateTime',
        'cancelledBy',
        'deactivatedBy',
        'reactivatedBy',
        'accountingApprover',
        'taxApprover'
    ]);

    return Object.keys(firstRegistrationRecord).every(key =>
        fieldsToExclude.has(key as keyof RegistrationRecord) ||
        firstRegistrationRecord[key as keyof RegistrationRecord] === secondRegistrationRecord[key as keyof RegistrationRecord]
    );
}
